/* Colors */

:root {
  --accent-color: #ff4f00;
  --background-color: #ffffff;
  --interaction-font-size: 12px;
  --button-background-color: lightgray;
}

/* General */

.flex-container {
  display: flex;
}

button {
  border-radius: 16px;
  padding: 8px 16px;
  border-width: 0px;
  background-color: var(--button-background-color);
  font-size: var(--interaction-font-size);
  color: black;
}

button:hover {
  background-color: grey;
  color: white;
}

button:disabled {
  background-color: var(--button-background-color);
  color: gray;
}

/* Top Nav Bar */

.nav-item {
  float: left;
}

.nav-item-right {
  float: right;
}

.nav-item a {
  text-decoration: none;
  display: block;
  color: lightgray;
  padding: 16px;
}

.nav-item a:hover {
  background-color: #111111;
}

.nav-bar {
  background-color: #343434;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  display: block;
}

.nav-horn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.nav-horn-item a {
  padding: 8px;
}

/* Friends List */
.friends-list {
  width: 100%;
  min-width: 180px;
  max-width: 360px;
}

/* Page Common Styling */

.page {
  padding: 16px;
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
}

/* Forms */

input[type="submit"] {
  width: 100%;
  background-color: var(--accent-color);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Modal */
.modal {
  position: absolute;
  top: 10%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* width: 100%;
  height: 100%; */
  /* bring your own prefixes */
  background-color: #3434344d;
  z-index: 999;
  display: block;
  overflow-y: scroll;
}

.modal-showing {
  display: block;
}

.modal-hidden {
  display: none;
}

/* Cells */

.cell-list {
  list-style: none;
  padding-left: 0;
}

.cell {
  border: gray;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.cell-clickable:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Layout Components */

.vertical-container {
  display: flex;
  flex-direction: column;
}

.horizontal-container {
  display: flex;
  align-items: center;
}

.container-spacer {
  flex-grow: 1;
}

/* Event horn button */

.event-horn {
  background-color: var(--accent-color);
}

.event-horn:hover {
  background-color: var(--accent-color);
  color: white;
}

/* Response Field */

.response-button {
  margin-right: 8px;
  margin-bottom: 8px;
}

.response-selected {
  background-color: gray;
  color: lightgray;
}

/* Response Cell */

.response-comment {
  font-style: italic;
}

/* Line Item */

.line-item {
  display: block;
  margin-top: 8px;
}
